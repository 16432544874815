import $ from 'jquery';
import validate from 'jquery-validation';
import * as d3 from 'd3v4';

import '../css/load.scss';

const $win = $(window);
const $doc = $(document);
const dt = require('datatables.net')();
const ionicons = require('ionicons');
const innerPage = $('.wrapper').hasClass('page-inner-dir');

initHeaderMenuToggler();
//initChartCurrencies();
handleSubscribeForm();
handleLoginForm();
handleSignupForm();
handleResetForm();
handleNewPassForm();
handlePortfolioForm();
handlePortfolioEdit();
handleAlertPopup();
handleProfileHistoryChange();
handleProfilePortfolioChange();
handlePortfolioDelete();

// Loader functions
const $loader = $('.app-loader');
function addLoader() {
	if (!$loader.length) {
		return;
	}

	$loader.addClass('active');
}
function removeLoader() {
	if (!$loader.length) {
		return;
	}

	$loader.removeClass('active');
}
// End of Loader functions

// Menu
function initHeaderMenuToggler() {
	$('.header .header__menu-toggle').on('click', function () {
		$(this).closest('.header__container').toggleClass('menu-open');
	});
}

// Column Size
function setSizeOfColumns() {
	if (!$('.section-dashboard .section__aside').length) {
		return;
	}

	$win.on('load resize scroll', function () {
		let transactionsHeight = $('.section__body').height();
		$('.section__aside').height(transactionsHeight);
	});
}

// Share button
$('.btn--share').on('click', function (e) {
	e.preventDefault();
	let portfolioId = $(this).data('portfolioid');

	$(this).parent('.share').find('.share__list').show();
	addPoints(portfolioId, innerPage);
});
$doc.on('click', function (e) {
	if (!$(e.target).parents('.btn--share').length && !$(e.target).hasClass('btn--share')) {
		$('.share__list').hide();
	}
});
$doc.on('touchend', function (e) {
	var container = $('.share__list');

	if (container.has(e.target).length === 0) {
		container.hide();
	}
});
$('.share__link').on('click', function (e) {
	e.preventDefault();
	copyToClipboard($(this).data('clipboard'));
});

// Init table
let table = $('#crypto-listing').DataTable({
	order: [[6, 'desc']]
});
let table_alt = $('#crypto-listing-alt').DataTable({
	order: [[4, 'desc']]
});

$('body').on('change', '#ajax-form', function (e) {
	if (e.target.type === 'search' || e.target.type === 'range') {
		return;
	}

	e.preventDefault();

	let data = table.$('input').serialize();
	let portfolio_id = $('#portfolio_id').val();
	data += `&portfolio_id=${portfolio_id}`;
	let searchValue = $("#ajax-form input[type='search']").val();

	updateCryptoQuantities(data, searchValue);
});

function handleProfileHistoryChange() {
	$('#portfolios-history').on('change', function (e) {
		const $trigger = $(this);
		const newValue = $trigger.val();
		if ($trigger.data('is-loading') === true) {
			return;
		}
		addLoader();
		$trigger.data('is-loading', true);

		$.ajax({
			type: 'POST',
			data: { portfolio_id: newValue },
			url: './ajax/history-profile-select.php'
		}).then((response) => {
			if (response.length === 0) {
				document.location.href = '/';
			} else {
				const responseHTML = $(response).html();
				$('#table').html(responseHTML);
				console.log('TEST')
				$trigger.data('is-loading', false);
				removeLoader();
			}
		});
	})
}

function handleProfilePortfolioChange() {
	$('.form-profile #portfolios').on('change', function (e) {
		const $trigger = $(this);
		const newValue = $trigger.val();
		if ($trigger.data('is-loading') === true) {
			return;
		}
		$trigger.data('is-loading', true);

		$.ajax({
			type: 'POST',
			data: { portfolio_id: newValue },
			url: './ajax/profile-select.php'
		}).then((response) => {
			let responseJson = JSON.parse(response);

			if (responseJson.status !== true) {
				document.location.href = '/';
			}

			let frequency = responseJson.frequency;
			let visibility = responseJson.visibility;
			let minLimitTotal = responseJson.min_limit_total;
			let maxLimitTotal = responseJson.max_limit_total;
			let crypto = responseJson.crypto;

			$('.form-profile input:radio').removeAttr('checked');
			$('.form-profile input:radio[name="notification"][value="' + frequency + '"]').attr(
				'checked',
				true
			);
			$('.form-profile input:radio[name="visibility"][value="' + visibility + '"]').attr(
				'checked',
				true
			);
			$('.form-profile #min-limit').val(minLimitTotal);
			$('.form-profile #max-limit').val(maxLimitTotal);
			$('.form-profile #currencies').val(crypto);
			$trigger.data('is-loading', false);
		});
	});
}

$('.form-explore #portfolios').on('change', function (e) {
	const $trigger = $(this);
	const newValue = $trigger.val();
	const searchValue = $("#ajax-form input[type='search']").val();

	if ($trigger.data('is-loading') === true) {
		return;
	}
	$trigger.data('is-loading', true);
	addLoader();

	$.ajax({
		type: 'POST',
		data: { portfolio_id: newValue },
		url: './ajax/change-project-table.php'
	}).then((response) => {
		if (response.length === 0) {
			document.location.href = '/';
		}

		const responseHTML = $(response).html();

		$('.form-listing').html(responseHTML);
		if ($.fn.dataTable.isDataTable('#crypto-listing')) {
			table = $('#crypto-listing').DataTable();
		} else {
			table = $('#crypto-listing').DataTable({
				order: [[6, 'desc']],
				oSearch: { sSearch: searchValue }
			});
		}

		$trigger.data('is-loading', false);
		removeLoader();
	});
});

function updateCryptoQuantities(cryptoQuantities, searchValue) {
	addLoader();

	$.ajax({
		type: 'POST',
		data: cryptoQuantities,
		url: './ajax/update-crypto-quantities.php'
	}).then((response) => {
		if (response.length === 0) {
			document.location.href = '/';
		}

		const responseHTML = $(response).html();

		$('.form-listing').html(responseHTML);
		if ($.fn.dataTable.isDataTable('#crypto-listing')) {
			table = $('#crypto-listing').DataTable();
		} else {
			table = $('#crypto-listing').DataTable({
				order: [[6, 'desc']],
				oSearch: { sSearch: searchValue }
			});
		}

		removeLoader();
	});
}

function addPoints(portfolioId, innerPage) {
	let urlPath = innerPage ? '../ajax/add-points.php' : './ajax/add-points.php';

	$.ajax({
		type: 'POST',
		data: { portfolio_id: portfolioId },
		url: urlPath
	}).then((response) => {
		// console.log(response);
	});
}

function initChartCurrencies() {
	if (!$('#chart-currencies').length) {
		return;
	}

	// Constants
	const margin = { top: 10, right: 10, bottom: 80, left: 80 };
	const widthOuter = 960;
	const heightOuter = 500;
	const width = widthOuter - margin.left - margin.right;
	const height = heightOuter - margin.top - margin.bottom;
	const xColumn = 'date';
	const yColumn = 'total';
	const defaultPortfolio = document.getElementById('portfolios').value;
	const defaultPeriod = document.getElementById('period').value;

	// SVG
	const svg = d3
		.select('#chart-currencies')
		.append('svg')
		.attr('viewBox', [0, 0, widthOuter, heightOuter]);

	// Tooltip
	const tooltip = d3
		.select('.section--chart')
		.append('div')
		.style('opacity', 0)
		.attr('class', 'tooltip')
		.style('pointer-events', 'none')
		.style('position', 'absolute')
		.style('white-space', 'pre')
		.style('background-color', '#ffffff')
		.style('border', 'solid')
		.style('border-width', '1px')
		.style('padding', '5px');
	const mouseover = function (d) {
		tooltip.style('opacity', 1);
	};
	const mousemove = function (d) {
		tooltip
			.html(
				`<b>${d['date']}</b>
<b>List</b>: ${d['list']}
<b>Total</b>: ${d['total']} USDT`
			)
			.style('left', d3.event.pageX - 90 + 'px')
			.style('top', d3.event.pageY - 16 + 'px');
	};
	const mouseleave = function (d) {
		tooltip.style('opacity', 0);
	};

	// Canvas
	const canvas = svg
		.append('g')
		.attr('id', 'canvas')
		.attr('width', width)
		.attr('height', height)
		.attr('transform', `translate(${margin.left}, ${margin.top})`);

	// Add X axis
	const x = d3.scaleTime().range([0, width]);
	const xAxisDetails = d3
		.axisBottom(x)
		.tickSizeOuter(0)
		.tickFormat(d3.timeFormat('%d %b %Y %H:%M'));
	const xAxis = canvas
		.append('g')
		.attr('transform', 'translate(0,' + height + ')')
		.attr('class', 'axis axis--x');

	// Add Y axis
	const y = d3.scaleLinear().range([height, 0]);
	const yAxisDetails = d3
		.axisLeft(y)
		.tickSizeOuter(0)
		.tickFormat(function (d, i) {
			return d + ' USDT';
		});
	const yAxis = canvas.append('g').attr('class', 'axis axis--y');

	// Add a clipPath: everything out of this area won't be drawn.
	const clip = canvas
		.append('clipPath')
		.attr('id', 'clip')
		.append('rect')
		.attr('width', width)
		.attr('height', height);

	// Zooming rect background
	canvas
		.append('g')
		.attr('id', 'valueLine')
		.append('rect')
		.attr('width', width)
		.attr('height', height)
		.attr('class', 'plot')
		.attr('pointer-events', 'all')
		.attr('fill', 'none');

	// Red background
	const redBackground = canvas
		.select('#valueLine')
		.append('rect')
		.attr('x', 0)
		.attr('class', 'classon')
		.attr('width', width)
		.attr('fill', 'red')
		.attr('opacity', 0.1);

	// Dots
	const valueDotContainer = canvas.select('#valueLine').append('g');

	// RENDER CHART
	renderChart(defaultPortfolio, defaultPeriod);

	// LISTEN TO THE BUTTON -> RENDER IF USER CHANGE IT
	d3.select('#portfolios').on('input', function () {
		const currentPeriod = document.getElementById('period').value;

		renderChart(+this.value, currentPeriod);
	});
	d3.select('#period').on('input', function () {
		const currentPortfolio = document.getElementById('portfolios').value;

		renderChart(+currentPortfolio, this.value);
	});

	function renderChart(portfolio, period) {
		d3.csv(
			`https://crypto.thirdbrainfx.com/includes/prices-csv.php?portfolio=${portfolio}`,
			function (row_data, row_index, column_names) {
				row_data[yColumn] = +row_data[yColumn];

				return row_data;
			},
			function (error, data) {
				if (error) throw error;

				// Bind new data
				const valueLine = svg.selectAll('.valueLine').data([data], function (d) {
					return d;
				});
				const valueDot = valueDotContainer.selectAll('circle').data(data);

				// Sort the data
				data.sort(function (a, b) {
					return new Date(b[xColumn]) - new Date(a[xColumn]);
				});

				// Update X axis
				if (period != 0) {
					const minDomainX = getMinDomain(period);
					const maxDomainX = new Date().setHours(23, 59, 59, 999);
					x.domain([minDomainX, maxDomainX]);
				} else {
					x.domain(
						d3.extent(
							data.map(function (item) {
								return new Date(item.whenopen);
							})
						)
					);
				}

				xAxis.call(xAxisDetails);
				xAxis
					.selectAll('text')
					.attr('text-anchor', 'end')
					.attr('transform', 'rotate(-20)')
					.attr('font-weight', '700');

				// Update Y axis
				const minDomainY = d3.min(data, function (d) {
					return +d.total - 2000;
				});
				const maxDomainY = d3.max(data, function (d) {
					return +d.total + 4000;
				});

				y.domain([minDomainY, maxDomainY]);
				yAxis.call(yAxisDetails);

				// valueLine
				valueLine
					.enter()
					.select('#valueLine')
					.attr('clip-path', 'url(#clip)')
					.insert('path', 'rect')
					.attr('class', 'valueLine')
					.merge(valueLine)
					.attr('fill', 'none')
					.attr('stroke', '#725000')
					.attr('stroke-width', 2)
					.attr(
						'd',
						d3
							.line()
							.x(function (d) {
								return x(new Date(d[xColumn]));
							})
							.y(function (d) {
								return y(d.total);
							})
					);
				valueLine.exit().remove();

				// Dots
				valueDot
					.enter()
					.append('circle')
					.attr('r', 3)
					.attr('fill', 'green')
					.attr('cx', function (d) {
						return x(new Date(d[xColumn]));
					})
					.attr('cy', function (d) {
						return y(d.total);
					})
					.on('mouseover', mouseover)
					.on('mousemove', mousemove)
					.on('mouseleave', mouseleave);
				valueDot.exit().remove();

				valueDot
					.attr('cx', function (d) {
						return x(new Date(d[xColumn]));
					})
					.attr('cy', function (d) {
						return y(d.total);
					})
					.attr('fill', 'green');

				// Background gray lines
				svg.selectAll('.axis--y > g.tick > line')
					.attr('x2', width)
					.style('stroke', 'lightgrey');
			}
		);
	}
}

function getMinDomain(periodDiff) {
	let tempDate = new Date();
	let tempDateDiff = 0;

	switch (periodDiff) {
		case '7d':
			tempDateDiff = 7;
			break;
		case '14d':
			tempDateDiff = 15;
			break;
		case '1m':
			tempDateDiff = 32;
			break;
		case '2m':
			tempDateDiff = 63;
			break;
		case '3m':
			tempDateDiff = 94;
			break;
		case '4m':
			tempDateDiff = 124;
			break;
		case '1y':
			tempDateDiff = 367;
			break;
		case '2y':
			tempDateDiff = 736;
			break;
		case '3y':
			tempDateDiff = 1101;
			break;
		case '4y':
			tempDateDiff = 1467;
			break;
		case '5y':
			tempDateDiff = 1837;
			break;
		case 'full':
			tempDateDiff = 2202;
			break;

		default:
			tempDateDiff = 6;
			break;
	}

	let firstDay = new Date(tempDate.getTime() - tempDateDiff * 24 * 60 * 60 * 1000);

	return firstDay;
}

function handleSubscribeForm() {
	let urlPathRule = innerPage ? '../form-validation/subscribe-email.php' : './form-validation/subscribe-email.php';
	let urlPathAjax = innerPage ? '../ajax/subscribe.php' : './ajax/subscribe.php';

	$('.form-subscribe form').validate({
		rules: {
			name: {
				required: true,
				minlength: 2
			},
			email: {
				required: true,
				email: true,
				remote: {
					url: urlPathRule,
					type: 'POST'
				}
			}
		},
		messages: {
			email: {
				remote: 'This email is already taken!'
			}
		},
		submitHandler: function (form) {
			addLoader();
			let formData = $(form).serialize();

			$.ajax({
				type: 'POST',
				data: formData,
				url: urlPathAjax
			}).then((response) => {
				let responseJson = JSON.parse(response);

				if (responseJson.status == true) {
					$('.form-subscribe .form__foot').show();
				}

				removeLoader();
			});

			return false;
		}
	});
}

function handleLoginForm() {
	$('.form-login form').validate({
		rules: {
			username: {
				required: true,
				minlength: 2
			},
			password: {
				required: true,
				minlength: 6
			}
		}
	});
}

function handleSignupForm() {
	$('.form-signup form').validate({
		rules: {
			name: {
				required: true,
				minlength: 2,
				maxlength: 22
			},
			surname: {
				required: true,
				minlength: 2,
				maxlength: 22
			},
			username: {
				required: true,
				minlength: 6,
				maxlength: 66,
				remote: {
					url: './form-validation/username.php',
					type: 'POST'
				}
			},
			email: {
				required: true,
				email: true,
				maxlength: 66,
				remote: {
					url: './form-validation/signup-email.php',
					type: 'POST'
				}
			},
			password: {
				required: true,
				minlength: 6,
				maxlength: 33
			},
			repassword: {
				equalTo: '#password',
				minlength: 6,
				maxlength: 33
			}
		},
		messages: {
			username: {
				remote: 'This username is already taken!'
			},
			email: {
				remote: 'This email is already taken!'
			}
		}
	});
}

function handleResetForm() {
	$('.form-reset form').validate({
		rules: {
			email: {
				required: true,
				email: true,
				maxlength: 66
			}
		}
	});
}

function handleNewPassForm() {
	$('.form-newpass form').validate({
		rules: {
			password: {
				required: true,
				minlength: 6,
				maxlength: 33
			},
			repassword: {
				equalTo: '#password',
				minlength: 6,
				maxlength: 33
			}
		}
	});
}

function handlePortfolioForm() {
	$('.form-portfolio form').validate({
		rules: {
			name: {
				required: true,
				minlength: 2,
				maxlength: 33
			}
		}
	});
}

function handlePortfolioEdit() {
	$('.action-edit').on('click', function (e) {
		e.preventDefault();

		let $trigger = $(this);
		let $portfolio = $trigger.parents('.portfolio');
		let $content_block = $portfolio.find('.portfolio__content');
		let $form_block = $portfolio.find('.portfolio__form');

		if ($trigger.hasClass('action-save')) {
			$content_block.show();
			$form_block.hide();
			$trigger.toggleClass('action-save').find('span').text('Edit');

			let formData = $form_block.find('form').serialize();

			addLoader();

			$.ajax({
				type: 'POST',
				data: formData,
				url: './ajax/update-portfolio.php'
			}).then((response) => {
				let responseJson = JSON.parse(response);

				if (responseJson.status !== true) {
					document.location.href = '/';
				}

				$content_block.find('h3').text(responseJson.name);
				$content_block.find('p').text(responseJson.description);

				removeLoader();
			});
		} else {
			$content_block.hide();
			$form_block.show();
			$trigger.toggleClass('action-save').find('span').text('Save');
			$form_block.find('input[type="text"]').get(0).focus();
		}
	});
}

function handlePortfolioDelete() {
	$('.action-delete').on('click', function (e) {
		e.preventDefault();

		let $trigger = $(this);
		let $portfolio = $trigger.parents('.portfolio');
		let portfolioId = $trigger.data('portfolio');

		if ($trigger.data('is-loading') === true) {
			return;
		}
		$trigger.data('is-loading', true);

		let confirmation = confirm('Are you sure you want to delete this portfolio?');

		if (confirmation) {
			$.ajax({
				type: 'POST',
				data: { portfolio_id: portfolioId },
				url: './ajax/portfolio-delete.php'
			}).then((response) => {
				let responseJson = JSON.parse(response);

				$portfolio.remove();

				$trigger.data('is-loading', false);
			});
		} else {
			$trigger.data('is-loading', false);
			return;
		}
	});
}

function handleAlertPopup() {
	$('body').on('click touchstart', '.section__alert', function () {
		$('.section__alert').remove();
	});
}

function copyToClipboard(textToCopy) {
	navigator.clipboard.writeText(textToCopy).then(() => {
		alert('Copied to clipboard');
	});
}

